import React, { useState, useEffect } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { Spinner6 } from '@styled-icons/icomoon/Spinner6'

import { toast } from 'react-toastify'
import { FirebaseInstance } from '../service/Firebase'
import { navigate } from 'gatsby'

import Layout from '../components/Layout'

import Logo from '../../static/assets/img/logo.png'

import * as S from '../styles/styles'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © Educação em Foco comércio de livros LTDA'}
    </Typography>
  )
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2)
  },
  main: {
    height: '100vh'
  }
}))

export default function SignIn() {
  const [login, setLogin] = useState(false)
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  })

  useEffect(() => {
    const loadData = async () => {
      const firebase = FirebaseInstance.getInstance()
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          navigate('/contact')
        }
      })
    }

    loadData()
  }, [])

  const classes = useStyles()

  const handleLogin = async () => {
    try {
      setLogin(true)
      const firebase = FirebaseInstance
      await firebase.auth(formData.email, formData.password)
      setLogin(false)

      navigate('/contact')
    } catch (error) {
      console.error(error.message)
      toast.error(
        `Ops...! Parece que o e-mail e/ou senha estão errados, verifique...`
      )
      setLogin(false)
    }
  }

  return (
    <Layout>
      <Container
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
        component="main"
        maxWidth="xs"
        className={classes.main}
      >
        <CssBaseline />
        <div className={classes.paper}>
          <img
            style={{ width: '45px', height: '60px' }}
            src={Logo}
            alt="Logo"
          />
          <div className={classes.form}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) =>
                setFormData({ ...formData, password: e.target.value })
              }
            />
            <S.LoginButton
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleLogin}
            >
              {login ? <Spinner6 size={26} fill="#fff" /> : 'ENTRAR'}
            </S.LoginButton>
          </div>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </Layout>
  )
}

import React from 'react'
import PropTypes from 'prop-types'

import { ToastContainer } from 'react-toastify'

import GlobalStyles from '../../styles/global'

import { LayoutWrapper, LayoutMain } from './styles'

export default function Layout({ children }) {
  return (
    <LayoutWrapper>
      <ToastContainer autoClose={3000} />
      <GlobalStyles />
      <LayoutMain>{children}</LayoutMain>
    </LayoutWrapper>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

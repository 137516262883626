import styled from 'styled-components'

export const LayoutWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`

export const LayoutMain = styled.main`
  width: 100%;
  background: #fafafa;
  position: relative;
  top: 0;
  height: 100%;
`

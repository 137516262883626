import firebase from 'gatsby-plugin-firebase'

class Firebase {
  constructor() {
    if (!Firebase.instance) {
      Firebase.instance = this
    }

    this._firebase = firebase

    return Firebase.instance
  }

  auth(email, password) {
    return new Promise((resolve, reject) => {
      this._firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then((result) => {
          resolve(result)
        })
        .catch((error) => reject(error))
    })
  }

  logout() {
    return new Promise((resolve, reject) => {
      this._firebase
        .auth()
        .signOut()
        .then(() => {
          resolve(true)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  create(contato) {
    return new Promise((resolve, reject) => {
      this._firebase
        .firestore()
        .collection('contatos')
        .add(contato)
        .then(function (docRef) {
          resolve({ ...contato, id: docRef.id })
        })
        .catch(function (error) {
          reject(error)
        })
    })
  }

  delete(document) {
    return new Promise((resolve, reject) => {
      this._firebase
        .firestore()
        .collection('contatos')
        .doc(document.id)
        .delete()
        .then(function () {
          resolve(true)
        })
        .catch(function (error) {
          reject(error)
        })
    })
  }

  getAll() {
    return new Promise((resolve, reject) => {
      this._firebase
        .firestore()
        .collection('contatos')
        .get()
        .then((querySnapshot) => {
          let resultList = []
          querySnapshot.forEach((doc) => {
            resultList.push({ id: doc.id, ...doc.data() })
          })

          resolve(resultList)
        })
        .catch((error) => reject(error))
    })
  }

  getInstance() {
    return this._firebase
  }
}

const FirebaseInstance = new Firebase()
Object.freeze(FirebaseInstance)

export { FirebaseInstance }

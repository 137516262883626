import styled, { keyframes } from 'styled-components'
import Button from '@material-ui/core/Button'

const rotate = keyframes`
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
`

export const LoginButton = styled(Button)`
  margin-top: 1rem;
  height: 50px;

  > span svg {
    animation: ${rotate} linear 1s infinite;
  }
`
